body {
  margin: 0;
  font-family: Anek Latin, Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;
  font-weight: 300;
}

a {
  text-decoration: none;
}
/*# sourceMappingURL=index.30da3bd1.css.map */
